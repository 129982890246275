var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.showLoading
        ? _c(
            "div",
            { staticClass: "loading-wrapper" },
            [
              _c("van-loading", { attrs: { type: "spinner" } }, [
                _vm._v("上传中..."),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("van-nav-bar", {
        attrs: {
          title: "上传验光报告",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _c(
        "div",
        { staticClass: "upload-container" },
        [
          _c("van-uploader", {
            attrs: {
              "before-read": _vm.beforeRead,
              "after-read": _vm.afterRead,
              "before-delete": _vm.beforeDelete,
              "max-count": _vm.UploadLimt,
              multiple: true,
              deletable: true,
            },
            model: {
              value: _vm.fileList,
              callback: function ($$v) {
                _vm.fileList = $$v
              },
              expression: "fileList",
            },
          }),
        ],
        1
      ),
      _vm.report_image.length !== 0
        ? _c("div", { staticClass: "show-container" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
              _vm._l(_vm.report_image, function (item, index) {
                return _c("van-image", {
                  key: index,
                  staticClass: "show-img-item",
                  attrs: { src: item, fit: "cover", "lazy-load": "" },
                  on: {
                    click: function ($event) {
                      return _vm.showImg(_vm.report_image, index)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "loading",
                        fn: function () {
                          return [
                            _c("van-loading", {
                              attrs: { type: "spiner", size: "20" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
          ])
        : _vm._e(),
      _c(
        "van-goods-action",
        [
          _c("van-goods-action-button", {
            attrs: { type: "info", text: "确认上传", loading: _vm.showLoading },
            on: { click: _vm.confirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }